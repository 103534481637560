<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Asignación Presupuestal: {{ id }}</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/tep">Tep</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                  <li class="breadcrumb-item active">
                    Asignación Presupuestal
                  </li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">
                  Formulario {{ accion }} Asignaciones presupuestales
                </h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <!---- Campo nombre de la tarifa ---->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="sitio">Sitio</label>
                      <v-select
                        :class="[
                          $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="sitio"
                        placeholder="Seleccione Sitio..."
                        label="nombre"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.sitios"
                        :disabled="estadodis"
                        @input="selectSitio()"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="empresa">Operación</label>
                      <v-select
                        :class="[
                          $v.form.operacion_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="operacion"
                        placeholder="Seleccione Empresa..."
                        label="descripcion"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.operaciones"
                        :disabled="estadodis"
                        @input="selectOperacion()"
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="empresa">Area</label>
                      <v-select
                        v-model="area"
                        placeholder="Seleccione Empresa..."
                        label="descripcion"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.areas"
                        :disabled="estadodis"
                        @input="selectArea()"
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button
                  class="btn bg-primary col-md-1"
                  v-show="!$v.form.$invalid"
                  @click="save(accion)"
                >
                  <i class="fas fa-paper-plane"></i><br />Guardar
                </button>
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i><br />Volver
                </button>
              </div>
            </div>

            <div
              v-if="
                accion == 'Editar' &&
                  $store.getters.can('tep.asignacionesPresupuestalesDet.index')
              "
              class="card"
            >
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Detalles</h5>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  v-if="
                    $store.getters.can(
                      'tep.asignacionesPresupuestalesDet.create'
                    )
                  "
                >
                  <div class="form-group col-md-4">
                    <label>Tipo Presupuesto</label>
                    <v-select
                      class="form-control form-control-sm p-0"
                      placeholder="Tipo Presupuesto"
                      label="descripcion"
                      v-model="formCeAf.frm_tipo_presupuesto"
                      :options="listasForms.tipo_presupuesto"
                      :disabled="listasForms.tipo_presupuesto.length == 0"
                      @input="validaFormCecoAfe('tipo_presupuesto')"
                    ></v-select>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="numero">Ceco Afe</label>
                    <v-select
                      class="form-control form-control-sm p-0"
                      placeholder="Código Contable"
                      label="codigo_contable"
                      v-model="formCeAf.frm_numero"
                      :options="listasForms.numero"
                      :disabled="
                        !formCeAf.frm_tipo_presupuesto ||
                          listasForms.numero.length == 0
                      "
                      @input="validaFormCecoAfe('numero')"
                    ></v-select>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="responsable">Responsable</label>
                    <v-select
                      class="form-control form-control-sm p-0"
                      placeholder="Responsable"
                      label="nombres"
                      v-model="formCeAf.frm_responsable"
                      :options="listasForms.respon"
                      :disabled="
                        !formCeAf.frm_numero || listasForms.respon.length == 0
                      "
                    ></v-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label for="porcentaje">Porcentaje</label>
                    <div class="input-group">
                      <input
                        class="form-control form-control-sm"
                        type="number"
                        min="0"
                        max="100"
                        v-model="formCeAf.porcentaje"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text form-control-sm">%</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mt-4 col-md-3">
                    <button
                      v-if="area"
                      type="button"
                      class="btn btn-success"
                      data-html="true"
                      data-toggle="tooltip"
                      v-show="!$v.formCeAf.$invalid"
                      @click="validFormDet()"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>

                <div class="modal-body pb-0">
                  <div class="row">
                    <div
                      class="col-md-12 text-center"
                      :class="
                        total_porcentaje == 100
                          ? 'text-success'
                          : total_porcentaje < 100
                          ? 'text-muted'
                          : 'text-danger'
                      "
                    >
                      <h4 class="mb-0">
                        Porcentaje Total: {{ total_porcentaje }}%
                      </h4>
                      <pre></pre>
                    </div>
                  </div>
                </div>
                <div
                  class="modal-body pb-0 table-responsive"
                  v-if="tipo_modal == 1"
                >
                  <table
                    id="tabla-pasajeros"
                    class="
                      table table-bordered table-striped table-hover table-sm
                    "
                  >
                    <thead>
                      <tr>
                        <th class="text-center">Tipo Presupuesto</th>
                        <th class="text-center">Código Contable</th>
                        <th class="text-center">Responsable</th>
                        <th class="text-center">Porcentaje</th>
                        <th class="text-center">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(cecoAfe, index) in detalles.data"
                        :key="cecoAfe.id"
                      >
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="
                              cecoAfe.tipo_presupuesto == 1 ||
                              cecoAfe.tipo_presupuesto == 1
                                ? 'bg-info'
                                : cecoAfe.tipo_presupuesto == 2 ||
                                  cecoAfe.tipo_presupuesto == 2
                                ? 'bg-navy'
                                : 'bg-lime'
                            "
                            >{{ cecoAfe.nTipoPresupuesto }}</span
                          >
                        </td>
                        <td>
                          <span
                            v-if="formActualizarCA && idActualizarCA == index"
                          >
                            <v-select
                              class="form-control form-control-sm p-0"
                              :class="[
                                $v.formCeAf2.frm_numero.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="formCeAf2.frm_numero"
                              placeholder="Código Contable"
                              label="codigo_contable"
                              :options="listasForms2.numero"
                              :disabled="listasForms2.numero.length == 0"
                              @input="validaFormCecoAfe2()"
                            ></v-select>
                          </span>
                          <span v-else>
                            {{ cecoAfe.ceco_afe.codigo_contable }}
                          </span>
                        </td>
                        <td>
                          <span
                            v-if="formActualizarCA && idActualizarCA == index"
                          >
                            <v-select
                              class="form-control form-control-sm p-0"
                              :class="[
                                $v.formCeAf2.frm_responsable.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="formCeAf2.frm_responsable"
                              placeholder="Responsable"
                              label="nombres"
                              :options="listasForms2.respon"
                              :disabled="
                                !formCeAf2.frm_numero ||
                                  listasForms2.respon.length == 0
                              "
                            ></v-select>
                          </span>
                          <span v-else>
                            {{ cecoAfe.responsable.nombres }}
                            {{ cecoAfe.responsable.apellidos }}
                          </span>
                        </td>
                        <td class="text-right">
                          <span
                            v-if="formActualizarCA && idActualizarCA == index"
                          >
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              id="porcentaje"
                              v-model="formCeAf2.porcentaje"
                              :class="
                                $v.formCeAf2.porcentaje.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </span>
                          <span v-else> {{ cecoAfe.porcentaje }}% </span>
                        </td>
                        <td class="text-right">
                          <span
                            v-if="formActualizarCA && idActualizarCA == index"
                          >
                            <button
                              v-if="!$v.formCeAf2.$invalid"
                              type="button"
                              class="btn btn-sm bg-success"
                              @click="guardarActualizacionCecoAfe(index)"
                            >
                              <i class="fas fa-save"></i>
                            </button>
                          </span>
                          <span v-else>
                            <button
                              v-if="
                                $store.getters.can(
                                  'tep.asignacionesPresupuestalesDet.edit'
                                )
                              "
                              type="button"
                              class="btn btn-sm bg-navy"
                              :disabled="
                                formActualizarCA && idActualizarCA != index
                              "
                              @click="verFormActualizarCecoAfe(index)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              v-if="
                                $store.getters.can(
                                  'tep.asignacionesPresupuestalesDet.delete'
                                )
                              "
                              type="button"
                              class="btn btn-sm btn-danger"
                              :disabled="
                                formActualizarCA && idActualizarCA != index
                              "
                              @click="borrarCecoAfe(cecoAfe.id)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div v-if="accion == 'Editar'" class="card-footer">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save(accion)"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "TepAsignacionesPresupuestalesForm",
  components: {
    vSelect,
    Loading,
  },

  data() {
    return {
      id: null,
      accion: "",
      metodo: "",
      firma_admin: 0,
      cargando: false,
      estadodis: false,
      sitio: {},
      empresa: {},
      tipo_modal: 1,
      data_modal: null,
      detalles: {},
      operacion: null,
      area: null,
      ceco_afe: null,
      tipo_presupuesto: null,
      estado: null,
      filtros: {},
      form: {
        sitio_id: null,
        area_id: null,
        operacion_id: null,
        modal_id: null,
        estado: null,
      },
      formAfe: {},
      liquidaciones: {},
      liquiDetalleVehis: [],
      detalleLiqui: [],
      chk_acumulan_km: [],
      detalleFacturacion: null,
      total_porcentaje: 0,
      listasForms: {
        sitios: [],
        empresas: [],
        estados: [],
        operaciones: [],
        areas: [],
        tipos_vehiculo: [],
        tipo_presupuesto: [],
        respon: [],
        numero: [],
        form2: {},
      },
      listasForms2: {
        numero: [],
        respon: [],
      },

      formActualizarCA: false,
      idActualizarCA: -1,
      formCeAf: {
        frm_tipo_presupuesto: null,
        frm_numero: null,
        frm_responsable: null,
        porcentaje: null,
      },
      formCeAf2: {
        frm_tipo_presupuesto: null,
        frm_numero: null,
        frm_responsable: null,
        porcentaje: null,
      },
      form1: {},
      cecosAfes: [],
      buscarDet: {
        vehiculo: "",
        cond: "",
        cond2: "",
        op: "",
        tv: "",
        turno: "",
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    if (
      this.form.operaciones &&
      this.form.operaciones.find((o) => o.numeracion === 2)
    ) {
      return {
        form: {
          sitio_id: {
            required,
          },
          operacion_id: {
            required,
          },
          area_id: {
            required,
          },
        },
        formCeAf: {
          frm_tipo_presupuesto: { required },
          frm_numero: { required },
          frm_responsable: { required },
          porcentaje: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
        },
        formCeAf2: {
          frm_tipo_presupuesto: { required },
          frm_numero: { required },
          frm_responsable: { required },
          porcentaje: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
        },
      };
    } else {
      return {
        form: {
          sitio_id: {
            required,
          },
          operacion_id: {
            required,
          },
          /* area_id: {
            required,
          }, */
        },
        formCeAf: {
          frm_tipo_presupuesto: { required },
          frm_numero: { required },
          frm_responsable: { required },
          porcentaje: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
        },
        formCeAf2: {
          frm_tipo_presupuesto: { required },
          frm_numero: { required },
          frm_responsable: { required },
          porcentaje: {
            required,
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
        },
      };
    }
  },

  methods: {
    async buscarEmpresas() {
      await axios
        .get("api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async buscarSitios() {
      await axios
        .get("api/admin/sitios/lista", {
          params: {
            estado: 1,
          },
        })
        .then((response) => {
          this.listasForms.sitios = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectSitio() {
      this.form.sitio_id = null;
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
        this.validarAsignacion();
      }
    },

    selectArea() {
      this.form.area_id = null;
      if (this.area) {
        this.form.area_id = this.area.numeracion;
        this.validarAsignacion();
      }
    },

    selectOperacion() {
      this.form.operacion_id = null;
      if (this.operacion) {
        this.form.operacion_id = this.operacion.numeracion;
        this.validarAsignacion();
      }
    },

    async getEstados() {
      await axios.get("/api/lista/100").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getOperaciones() {
      await axios.get("/api/lista/80").then((response) => {
        this.listasForms.operaciones = response.data;
      });
    },

    async getAreas() {
      await axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    getTipoPresupuesto() {
      this.listasForms.tipo_presupuesto = [];
      axios.get("/api/lista/151").then((response) => {
        this.listasForms.tipo_presupuesto = response.data;
      });
    },

    getNumeroContable(filtros = null) {
      this.listasForms.numero = [];
      axios
        .get("/api/admin/cecosYafes/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms.numero = response.data;
        });
    },

    validaFormCecoAfe(campo = null) {
      switch (campo) {
        case "tipo_presupuesto":
          if (this.formCeAf.frm_tipo_presupuesto) {
            if (
              this.formCeAf.frm_numero &&
              this.formCeAf.frm_numero.tipo_presupuesto !=
                this.formCeAf.frm_tipo_presupuesto.numeracion
            ) {
              this.formCeAf.frm_numero = null;
              this.formCeAf.frm_responsable = null;
            }
            this.getNumeroContable({
              tipo_presupuesto:
                this.formCeAf.frm_tipo_presupuesto.numeracion == 1 ? 1 : 2,
            });
          } else {
            // Se inicial las listas y campos
            this.listasForms.numero = [];
            this.formCeAf.frm_numero = null;
            this.listasForms.respon = [];
            this.formCeAf.frm_responsable = null;
          }
          break;

        case "numero":
          if (this.formCeAf.frm_numero) {
            // Se valida si existe un funcionario dentro de la numeracion contable
            this.listasForms.respon = [];
            this.formCeAf.frm_responsable = null;
            if (this.formCeAf.frm_numero.funcionario) {
              const data_respons = {
                id: this.formCeAf.frm_numero.funcionario.id,
                nombres: `${this.formCeAf.frm_numero.funcionario.nombres} ${this.formCeAf.frm_numero.funcionario.apellidos}`,
                user_id: this.formCeAf.frm_numero.funcionario.user_id,
              };
              this.listasForms.respon.push(data_respons);
              this.formCeAf.frm_responsable = data_respons;
            } else {
              this.getResponsable();
            }
          } else {
            // Se inicial las listas y campos
            this.listasForms.respon = [];
            this.formCeAf.frm_responsable = null;
          }
          break;

        default:
          break;
      }
    },

    verFormActualizarCecoAfe(cecosAfes_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos

      this.idActualizarCA = cecosAfes_id;
      this.formCeAf2.id = cecosAfes_id;
      this.formCeAf2.frm_responsable = this.detalles.data[
        cecosAfes_id
      ].responsable;
      this.formCeAf2.frm_tipo_presupuesto = this.detalles.data[
        cecosAfes_id
      ].tipo_presupuesto;
      this.formCeAf2.porcentaje = this.detalles.data[cecosAfes_id].porcentaje;
      this.formCeAf2.frm_numero = this.detalles.data[cecosAfes_id].ceco_afe;

      // Mostramos el formulario
      this.formActualizarCA = true;

      // Se valida la lista del formulario
      this.getNumeroContable2({
        tipo_presupuesto:
          this.detalles.data[cecosAfes_id].tipo_presupuesto.numeracion == 1
            ? 1
            : 2,
      });

      // Se valida si existe un funcionario dentro de la numeracion contable
      this.listasForms2.respon = [];
      if (this.detalles.data[cecosAfes_id].ceco_afe.funcionario) {
        this.listasForms2.respon.push({
          id: this.detalles.data[cecosAfes_id].ceco_afe.funcionario.id,
          nombres: `${this.detalles.data[cecosAfes_id].ceco_afe.funcionario.nombres} ${this.detalles.data[cecosAfes_id].ceco_afe.funcionario.apellidos}`,
          user_id: this.detalles.data[cecosAfes_id].ceco_afe.funcionario
            .user_id,
        });
      } else {
        this.getResponsable2();
      }
    },

    getNumeroContable2(filtros = null) {
      axios
        .get("/api/admin/cecosYafes/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms2.numero = response.data;
        });
    },

    getResponsable2() {
      // Se filtra que el funcionario tenga un usuario asignado
      axios
        .get("/api/admin/funcionarios/lista", {
          params: { con_usuario: 1 },
        })
        .then((response) => {
          this.listasForms2.respon = response.data;
        });
    },

    validaFormCecoAfe2() {
      if (this.formCeAf2.frm_numero) {
        if (
          this.formCeAf2.frm_responsable &&
          this.formCeAf2.frm_responsable.id !=
            this.formCeAf2.frm_numero.funcionario_id
        ) {
          this.formCeAf2.frm_responsable = null;
        }
        // Se valida si existe un funcionario dentro de la numeracion contable
        this.listasForms2.respon = [];
        this.formCeAf2.frm_responsable = null;
        if (this.formCeAf2.frm_numero.funcionario) {
          const data_respons = {
            id: this.formCeAf2.frm_numero.funcionario.id,
            nombres: `${this.formCeAf2.frm_numero.funcionario.nombres} ${this.formCeAf2.frm_numero.funcionario.apellidos}`,
            user_id: this.formCeAf2.frm_numero.funcionario.user_id,
          };
          this.listasForms2.respon.push(data_respons);
          this.formCeAf2.frm_responsable = data_respons;
        } else {
          this.getResponsable2();
        }
      } else {
        // Se inicial las listas y campos
        this.listasForms2.respon = [];
        this.formCeAf2.frm_responsable = null;
      }
    },

    async save() {
      this.cargando = true;
      await axios({
        method: this.metodo,
        url: "/api/tep/asignacionesPresupuestalesDetalles",
        data: this.form,
      })
        .then(async (response) => {
          this.$route.params.accion = "Editar";
          this.$route.params.id = response.data.id;
          this.$route.params.data_edit = response.data;
          await this.init();
          //await this.getDetalle();
          this.$swal({
            icon: "success",
            title: "Se guardo la asignación correctamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(async (e) => {
          this.cargando = false;
          await this.$swal({
            icon: "error",
            title: "Ha ocurrido un error, intentelo nuevamente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async init() {
      this.cargando = true;
      await this.buscarSitios();
      await this.getAreas();
      await this.getOperaciones();

      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;

      if (this.$route.params.accion == "Editar") {
        this.form.sitio_id = this.$route.params.data_edit.sitio_id;
        this.form.area_id = this.$route.params.data_edit.area;
        this.form.operacion_id = this.$route.params.data_edit.operacion;
        this.form.estado = 2;
        this.form.modal_id = this.$route.params.data_edit.id;
        this.filtros.det_id = this.$route.params.data_edit.id;
        this.form.id = this.$route.params.data_edit.id;

        let find_sit = await this.listasForms.sitios.find(
          (e) => e.id === this.form.sitio_id
        );
        this.sitio = find_sit;

        let find_area = await this.listasForms.areas.find(
          (e) => e.numeracion === this.form.area_id
        );
        this.area = find_area;

        let find_operacion = await this.listasForms.operaciones.find(
          (e) => e.numeracion === this.form.operacion_id
        );
        this.operacion = find_operacion;

        await this.getDetalles();
        this.porcentajeTotal();
        this.metodo = "PUT";
        this.cargando = false;
      } else {
        this.metodo = "POST";
        this.cargando = false;
      }
    },

    back() {
      return this.$router.replace("/Tep/AsignacionesPresupuestales");
    },

    async guardarActualizacionCecoAfe(cecosAfes_id) {
      // Se valida el valor del porcentaje
      let flag_porcentaje = this.total_porcentaje;
      flag_porcentaje -= Number(this.detalles.data[cecosAfes_id].porcentaje);
      flag_porcentaje += Number(this.formCeAf2.porcentaje);

      if (flag_porcentaje > 100) {
        this.$swal({
          title: "Excedió la cantidad de ceco y afe permitida",
          text: `El límite del porcentaje es 100% de Ceco o Afe, por favor validar..`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
        flag_porcentaje -= Number(this.formCeAf2.porcentaje);
        this.formCeAf2.porcentaje = this.detalles.data[cecosAfes_id].porcentaje;
        flag_porcentaje += Number(this.formCeAf2.porcentaje);
      } else {
        // Actualizamos los datos
        this.formCeAf2.id = this.detalles.data[cecosAfes_id].id;
        await this.saveFacturacionAfeCeco();
      }
      // Se actualiza el valor del porcentaje
      this.total_porcentaje = flag_porcentaje;
    },

    borrarCecoAfe(cecosAfes_id) {
      this.$swal({
        title: "Está seguro de quitar el Ceco o Afe?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          axios
            .post(
              "/api/tep/asignacionesPresupuestalesDetalles/deleteDetFacturacionCecoAfe",
              { id: cecosAfes_id }
            )
            .then((response) => {
              this.init();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    porcentajeTotal() {
      this.total_porcentaje = null;
      this.detalles.data.forEach((element) => {
        this.total_porcentaje =
          this.total_porcentaje + Number(element.porcentaje);
      });

      if (this.formCeAf.porcentaje) {
        this.total_porcentaje =
          this.total_porcentaje + Number(this.formCeAf.porcentaje);
        // Se valida el total del porcentaje si es mayor a 100%
        if (this.total_porcentaje > 100) {
          this.$swal({
            title: "Excedio la cantidad de ceco y afe permitida",
            text: `El limite del porcentaje es 100% de Ceco o Afe, por favor validar..`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2000,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          });
          this.total_porcentaje =
            this.total_porcentaje - Number(this.formCeAf.porcentaje);
          this.cecosAfes.pop();
        }
      }
    },

    async cargarCeco() {
      this.filtros.det_id = null;
      this.filtros.det_id = this.form.modal_id;
      this.getDetalles();
      this.formActualizarCA = false;
    },

    getResponsable() {
      // Se filtra que el funcionario tenga un usuario asignado
      axios
        .get("/api/admin/funcionarios/lista", {
          params: { con_usuario: 1 },
        })
        .then((response) => {
          this.listasForms.respon = response.data;
        });
    },

    async validFormDet() {
      let flag_porcentaje = this.total_porcentaje;
      flag_porcentaje += Number(this.formCeAf.porcentaje);
      if (flag_porcentaje > 100) {
        this.$swal({
          title: "Excedió la cantidad de ceco y afe permitida",
          text: `El límite del porcentaje es 100% de Ceco o Afe, por favor validar..`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
        });
      } else {
        await this.saveFacturacionAfeCeco();
      }
    },

    async saveFacturacionAfeCeco() {
      if (this.formActualizarCA) {
        this.formAfe.tep_det_facturacion_interno_id = this.form.id;
        this.formAfe.id = this.formCeAf2.id;
        this.formAfe.tipo_presupuesto = this.formCeAf2.frm_tipo_presupuesto;
        this.formAfe.ceco_afe = this.formCeAf2.frm_numero;
        this.formAfe.responsable = this.formCeAf2.frm_responsable;
        this.formAfe.porcentaje = parseFloat(this.formCeAf2.porcentaje);
        this.formActualizarCA = false;
        this.formCeAf.frm_numero = null;
        this.formCeAf.frm_tipo_presupuesto = null;
        this.formCeAf.frm_responsable = null;
        this.formCeAf.porcentaje = null;
        this.cargando = true;
        this.metodo = "PUT";
      } else {
        this.cargarCeco();
        this.formAfe.id = null;
        this.formAfe.tep_det_facturacion_interno_id = this.form.id;
        this.formAfe.ceco_afe = this.formCeAf.frm_numero;
        this.formAfe.tipo_presupuesto = this.formCeAf.frm_tipo_presupuesto.numeracion;
        this.formAfe.responsable = this.formCeAf.frm_responsable.id;
        this.formAfe.porcentaje = parseFloat(this.formCeAf.porcentaje);
        this.formCeAf.frm_numero = null;
        this.formCeAf.frm_tipo_presupuesto = null;
        this.formCeAf.frm_responsable = null;
        this.formCeAf.porcentaje = null;
        this.cargando = true;
        this.metodo = "POST";
      }
      await axios({
        method: this.metodo,
        url:
          "/api/tep/asignacionesPresupuestalesDetalles/saveFacturacionCecoAfe",
        data: this.formAfe,
      })
        .then(async (response) => {
          this.init();
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(async (e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getDetalles(page = 1) {
      await axios
        .get("/api/tep/asignacionesPresupuestalesDetalles?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.detalles = response.data;
        });
    },

    validarAsignacion() {
      if (this.form.sitio_id && this.form.operacion_id && this.form.area_id) {
        axios
          .get("/api/tep/asignacionesPresupuestales/lista", {
            params: {
              sitio_id: this.form.sitio_id,
              operacion_id: this.form.operacion_id,
              area_id: this.form.area_id,
            },
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.limpiarForm();
              this.$swal({
                icon: "error",
                title:
                  "La asignación ya se encuentra creada en la base de datos.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          });
      }
    },

    limpiarForm() {
      this.form = {
        sitio_id: null,
        operacion_id: null,
        area_id: null,
      };
      this.sitio = {};
      this.operacion = {};
      this.area = {};
    },
  },

  async mounted() {
    await this.init();
    await this.getTipoPresupuesto();
  },
};
</script>
