var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Asignación Presupuestal: "+_vm._s(_vm.id))])]),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/tep"}},[_vm._v("Tep")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Internos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Liquidaciones")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Asignación Presupuestal ")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pt-2 pb-2 bg-frontera text-white"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" Asignaciones presupuestales ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"sitio"}},[_vm._v("Sitio")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                        _vm.$v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                        _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      ],attrs:{"placeholder":"Seleccione Sitio...","label":"nombre","options":_vm.listasForms.sitios,"disabled":_vm.estadodis},on:{"input":function($event){return _vm.selectSitio()}},model:{value:(_vm.sitio),callback:function ($$v) {_vm.sitio=$$v},expression:"sitio"}})],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"empresa"}},[_vm._v("Operación")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                        _vm.$v.form.operacion_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      ],attrs:{"placeholder":"Seleccione Empresa...","label":"descripcion","options":_vm.listasForms.operaciones,"disabled":_vm.estadodis},on:{"input":function($event){return _vm.selectOperacion()}},model:{value:(_vm.operacion),callback:function ($$v) {_vm.operacion=$$v},expression:"operacion"}})],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"empresa"}},[_vm._v("Area")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",attrs:{"placeholder":"Seleccione Empresa...","label":"descripcion","options":_vm.listasForms.areas,"disabled":_vm.estadodis},on:{"input":function($event){return _vm.selectArea()}},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})],1)])])]),_c('div',{staticClass:"card-footer"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.save(_vm.accion)}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]),_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")])])]),(
              _vm.accion == 'Editar' &&
                _vm.$store.getters.can('tep.asignacionesPresupuestalesDet.index')
            )?_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(
                  _vm.$store.getters.can(
                    'tep.asignacionesPresupuestalesDet.create'
                  )
                )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('label',[_vm._v("Tipo Presupuesto")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",attrs:{"placeholder":"Tipo Presupuesto","label":"descripcion","options":_vm.listasForms.tipo_presupuesto,"disabled":_vm.listasForms.tipo_presupuesto.length == 0},on:{"input":function($event){return _vm.validaFormCecoAfe('tipo_presupuesto')}},model:{value:(_vm.formCeAf.frm_tipo_presupuesto),callback:function ($$v) {_vm.$set(_vm.formCeAf, "frm_tipo_presupuesto", $$v)},expression:"formCeAf.frm_tipo_presupuesto"}})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"numero"}},[_vm._v("Ceco Afe")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",attrs:{"placeholder":"Código Contable","label":"codigo_contable","options":_vm.listasForms.numero,"disabled":!_vm.formCeAf.frm_tipo_presupuesto ||
                        _vm.listasForms.numero.length == 0},on:{"input":function($event){return _vm.validaFormCecoAfe('numero')}},model:{value:(_vm.formCeAf.frm_numero),callback:function ($$v) {_vm.$set(_vm.formCeAf, "frm_numero", $$v)},expression:"formCeAf.frm_numero"}})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"responsable"}},[_vm._v("Responsable")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",attrs:{"placeholder":"Responsable","label":"nombres","options":_vm.listasForms.respon,"disabled":!_vm.formCeAf.frm_numero || _vm.listasForms.respon.length == 0},model:{value:(_vm.formCeAf.frm_responsable),callback:function ($$v) {_vm.$set(_vm.formCeAf, "frm_responsable", $$v)},expression:"formCeAf.frm_responsable"}})],1),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"porcentaje"}},[_vm._v("Porcentaje")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formCeAf.porcentaje),expression:"formCeAf.porcentaje"}],staticClass:"form-control form-control-sm",attrs:{"type":"number","min":"0","max":"100"},domProps:{"value":(_vm.formCeAf.porcentaje)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formCeAf, "porcentaje", $event.target.value)}}}),_vm._m(1)])]),_c('div',{staticClass:"form-group mt-4 col-md-3"},[(_vm.area)?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.formCeAf.$invalid),expression:"!$v.formCeAf.$invalid"}],staticClass:"btn btn-success",attrs:{"type":"button","data-html":"true","data-toggle":"tooltip"},on:{"click":function($event){return _vm.validFormDet()}}},[_c('i',{staticClass:"fa fa-plus"})]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"modal-body pb-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-center",class:_vm.total_porcentaje == 100
                        ? 'text-success'
                        : _vm.total_porcentaje < 100
                        ? 'text-muted'
                        : 'text-danger'},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Porcentaje Total: "+_vm._s(_vm.total_porcentaje)+"% ")]),_c('pre')])])]),(_vm.tipo_modal == 1)?_c('div',{staticClass:"modal-body pb-0 table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm",attrs:{"id":"tabla-pasajeros"}},[_vm._m(2),_c('tbody',_vm._l((_vm.detalles.data),function(cecoAfe,index){return _c('tr',{key:cecoAfe.id},[_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:cecoAfe.tipo_presupuesto == 1 ||
                            cecoAfe.tipo_presupuesto == 1
                              ? 'bg-info'
                              : cecoAfe.tipo_presupuesto == 2 ||
                                cecoAfe.tipo_presupuesto == 2
                              ? 'bg-navy'
                              : 'bg-lime'},[_vm._v(_vm._s(cecoAfe.nTipoPresupuesto))])]),_c('td',[(_vm.formActualizarCA && _vm.idActualizarCA == index)?_c('span',[_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                              _vm.$v.formCeAf2.frm_numero.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              _vm.$store.getters.getDarkMode
                                ? 'dark-vselect'
                                : '',
                            ],attrs:{"placeholder":"Código Contable","label":"codigo_contable","options":_vm.listasForms2.numero,"disabled":_vm.listasForms2.numero.length == 0},on:{"input":function($event){return _vm.validaFormCecoAfe2()}},model:{value:(_vm.formCeAf2.frm_numero),callback:function ($$v) {_vm.$set(_vm.formCeAf2, "frm_numero", $$v)},expression:"formCeAf2.frm_numero"}})],1):_c('span',[_vm._v(" "+_vm._s(cecoAfe.ceco_afe.codigo_contable)+" ")])]),_c('td',[(_vm.formActualizarCA && _vm.idActualizarCA == index)?_c('span',[_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                              _vm.$v.formCeAf2.frm_responsable.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              _vm.$store.getters.getDarkMode
                                ? 'dark-vselect'
                                : '',
                            ],attrs:{"placeholder":"Responsable","label":"nombres","options":_vm.listasForms2.respon,"disabled":!_vm.formCeAf2.frm_numero ||
                                _vm.listasForms2.respon.length == 0},model:{value:(_vm.formCeAf2.frm_responsable),callback:function ($$v) {_vm.$set(_vm.formCeAf2, "frm_responsable", $$v)},expression:"formCeAf2.frm_responsable"}})],1):_c('span',[_vm._v(" "+_vm._s(cecoAfe.responsable.nombres)+" "+_vm._s(cecoAfe.responsable.apellidos)+" ")])]),_c('td',{staticClass:"text-right"},[(_vm.formActualizarCA && _vm.idActualizarCA == index)?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formCeAf2.porcentaje),expression:"formCeAf2.porcentaje"}],staticClass:"form-control form-control-sm",class:_vm.$v.formCeAf2.porcentaje.$invalid
                                ? 'is-invalid'
                                : 'is-valid',attrs:{"type":"number","id":"porcentaje"},domProps:{"value":(_vm.formCeAf2.porcentaje)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formCeAf2, "porcentaje", $event.target.value)}}})]):_c('span',[_vm._v(" "+_vm._s(cecoAfe.porcentaje)+"% ")])]),_c('td',{staticClass:"text-right"},[(_vm.formActualizarCA && _vm.idActualizarCA == index)?_c('span',[(!_vm.$v.formCeAf2.$invalid)?_c('button',{staticClass:"btn btn-sm bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.guardarActualizacionCecoAfe(index)}}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e()]):_c('span',[(
                              _vm.$store.getters.can(
                                'tep.asignacionesPresupuestalesDet.edit'
                              )
                            )?_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button","disabled":_vm.formActualizarCA && _vm.idActualizarCA != index},on:{"click":function($event){return _vm.verFormActualizarCecoAfe(index)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(
                              _vm.$store.getters.can(
                                'tep.asignacionesPresupuestalesDet.delete'
                              )
                            )?_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button","disabled":_vm.formActualizarCA && _vm.idActualizarCA != index},on:{"click":function($event){return _vm.borrarCecoAfe(cecoAfe.id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()])])])}),0)])]):_vm._e()])]):_vm._e()])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header pt-2 pb-2 bg-frontera text-white"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Detalles")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text form-control-sm"},[_vm._v("%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Tipo Presupuesto")]),_c('th',{staticClass:"text-center"},[_vm._v("Código Contable")]),_c('th',{staticClass:"text-center"},[_vm._v("Responsable")]),_c('th',{staticClass:"text-center"},[_vm._v("Porcentaje")]),_c('th',{staticClass:"text-center"},[_vm._v("Acciones")])])])
}]

export { render, staticRenderFns }